body{
    font-family: 'Poppins', sans-serif;
    margin: 0;
}
header{
    text-align: center;
    padding-bottom: 0;
}
.container-fluid {
    width: 95% !important;
    position: relative;
}
.errorMessage {
    text-align: left !important;
    color: red;
}
.logo{
    color: #522D5B;
    font-size: 60px;
    margin: 0;
    font-weight: 900;
    font-family: 'Montserrat', sans-serif;  
    margin-top: 20px;
    /* z-index: 99999; */
}
.logo a{
    color: #522D5B;
}
.logo a:hover{
    text-decoration: none;
}
.table-bordered {
    border: 1px solid black !important;
}
.right-icon{
    position: absolute;
    right: 0;
    top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* z-index: 99999; */
}
.right-icon>a{
    /* display: inline-block; */
    margin-right: 10px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    background-color: rgba(0, 0, 0, 0.35) !important;
}
.right-icon img{
    width: 50px;
}
.right-icon .dropdown.user_profile{
    display: inline-block; cursor: pointer;
}
.dropdown.user_profile .dropdown-menu {
    left: initial !important;
    right: 0 !important;
    transform: translate3d(0px, 5px, 0px) !important;
    padding: 0px;
    min-width: 12em;
    border-color: #522D5B;
}
.dropdown.user_profile .dropdown-menu a {
    padding: 10px 15px;
    color: #000;
    text-decoration: none;
    display: block;
    font-size: 14px;
    border-bottom: 1px solid #522D5B;
    text-align: center;
}
.dropdown.user_profile .dropdown-menu a:hover {
    background: #522D5B;
    color: #fff;
}
.dropdown.user_profile a span.caret {
    width: 10px;
    margin-left: 10px;
    display: inline-block;
}
.dropdown.user_profile .dropdown-menu li:last-child a {
    border: 0
}
.top-row{
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
}
.top-row.landing_page{justify-content: flex-start;}
.top-row li{
    font-size: 22px;
    align-items: center;
    display: flex;
     
    padding-right: 20px;
    color: #4A4A4A;
}
.top-row.right  { 
    justify-content: flex-end;
}
.top-row li img{
    margin-right: 10px;
    height: 24px;
}
.top-section hr{
    width: 38%;
    border: none;
    line-height: 0;
    height: 1px;
    overflow: hidden;
    background: black;
    opacity: 0.3;
    margin-top: 8px;
    margin-bottom: 8px;
}

.userpanel{
    background: #F3F3F3;
    margin-top: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.userpanel h3{
    font-size: 20px;
    color: #4A4A4A;
    margin-left: 19px;
}
.userpanel .item{
    background: #fff;
    border: 1px solid #A3A3A338;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
}
.userpanel .item p{
    margin-bottom: 0;
    line-height: 1.4;
}
.userpanel .item img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 10px;
}
.table-1 .table{
 margin-bottom: 0;   
}
.table-1{
    box-shadow: 0px 15px 24px #6666E24F;
}
.table-2{
    box-shadow: none;
    border: none;
}
.table-1 tr td{
    width: 5%;
    color: black;
    font-size: 30px;
    padding: 0;
}
.table-1 tr td div{
    text-align: center;
    height: 74px;
    padding-top: 18px;
}
.table-1 tr td div.done-number{
    background-color: #FB7B6B;
    color: white;
}
.table-1 tr td div.active{
    background: #FB7B6B;
    border-radius: 10px;
    text-align: center;
    color: #fff;
}
.right-card{
    padding-left: 50px;
    padding-right: 50px;
}
.next-card{
    box-shadow: 0px 3px 5px #6666E24F;
    border: 1px solid #a3a3a333;
    padding: 20px 30px;
    border-radius: 10px;
    display: flex;
    width: 255px;
}
.next-card .number{
    background-color: #FB7B6B;
    border-radius: 15px;
    font-size: 1.8vw;
    font-weight: 700;
    min-height: 120px;
    /* padding: 10px !important; */
   /* line-height: 0;*/
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #fff;
    /* margin-right: 10px; */
}
.next-card .bottom-content {
    margin-top: 20px;
    margin-left: 17px;
}
.next-card .bottom-content small{
    color: #6666E2;
    font-size: 14px;
}
.next-card .bottom-content p{
    margin: 0;
    font-size: 65px;
    line-height: 0.7;
}
.next-card.counter_down {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px;
    overflow: hidden;
    align-items: center;
    background: #fff;
    border: 0;
    box-shadow: 0px 0px 24px #6666E24F;
    text-align: center;
}

.next-card.counter_down h2 {
    font-size: 16px;
    text-transform: capitalize;
    padding: 5px 15px;
    background: #522D5B;
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 0px 4px #0a0a0a4f;
    color: #fff;
    margin-bottom: 10px;
    width: 100%;
}
.ticketNo{
    color: #FFFFFF;
    background: #676767;
    border-radius: 5px;
    font-size: 15px;
    border: none;
    max-width: 400px;
    font-weight: 300;
    cursor: pointer;
    padding: 6px;
    margin-bottom: 8px;
    float: left;
}
.ticketNo b {
    font-weight: bold;
    margin-right: 5px;
}
.ticket-section .table-responsive{
    /* max-width: 500px; */
    margin: 1em 0 0;
}
.last_five_nums {
    box-shadow: 0px 0px 24px #6666E24F;
    max-width: 250px;
    text-align: center;
    margin-left: 74px;
}
.last_five_nums ul {
    list-style: none;
    display: flex;    
    padding: 0;
    margin: 0;
}

.last_five_nums ul li {
    flex: 1 1 auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 700;
    box-shadow: 0px 0px 4px #0a0a0a4f;
    margin: 0 4px;
}
.count_watch span {
    background: #fb6e5d;
    margin: 0 5px;
    color: #fff;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 15px;
    font-style: italic;
}
.claim-btn{
    background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #FFFFFF;
    /* font-size: 20px; */
    border: none;
    /* max-width: 168px; */
    font-weight: 300;
    margin: auto;
    cursor: pointer;
    padding: 6px;
    width: 100%;
}
.ticket2 .claim-btn{
    background: transparent linear-gradient(180deg, #9A3BB1 0%, #522D5B 100%) 0% 0% no-repeat padding-box;
}
.ticket2 .table-1 tr td div.active{
    background: #522D5B;
}
.ticket3 .claim-btn{
    background: transparent linear-gradient(180deg, #ca786f 0%, #5a4441 100%) 0% 0% no-repeat padding-box;
}
.ticket3 .table-1 tr td div.active{
    background: #b87068;
}
.ticket4 .claim-btn{
    background: transparent linear-gradient(180deg, #d093de 0%, #776e79 100%) 0% 0% no-repeat padding-box;
}
.ticket4 .table-1 tr td div.active{
    background: #b989c3;
}
.ticket6 .claim-btn{
    background: transparent linear-gradient(180deg, #9A3BB1 0%, #522D5B 100%) 0% 0% no-repeat padding-box;
}
.ticket6 .table-1 tr td div.active{
    background: #522D5B;
}
.ticket7 .claim-btn{
    background: transparent linear-gradient(180deg, #ca786f 0%, #5a4441 100%) 0% 0% no-repeat padding-box;

}
.ticket7 .table-1 tr td div.active{
    background: #b87068;
}
.ticket8 .claim-btn{
    background: transparent linear-gradient(180deg, #d093de 0%, #776e79 100%) 0% 0% no-repeat padding-box;
}
.ticket8 .table-1 tr td div.active{
    background: #b989c3;
}

.full-fouse{
    background: #FB6856 !important;
    color: #ffffff !important;
}
.button-group .btn{
    width: 96%;
    background: transparent;
    border: 2px solid #FB6856;
    border-radius: 10px;
    font-size: 20px;
    padding-right: 0;
    padding-left: 0;
}
.button-group .btn:disabled{
    border: 2px solid #BBBBBB;
    color:black;
}

.rules{
    margin-top: 50px;
}
.rules h2{
    color: #522D5B;
    font-size: 28px;

}
.rules ul{
    color: #5B5B5B;
    font-size: 20px;
}
.rules ul li{
    padding-bottom: 20px;
}

.winning{
    margin-top: 0;
}
.winning h2{
    color: #522D5B;
    font-size: 28px;
}
.winning ul{
    color: #5B5B5B;
    line-height: 1.8;
    font-size: 20px;
    list-style: none;
    padding-left: 0;
}
.players{
    margin-bottom: 32px;
}
.players ul{
padding-left: 0;
list-style: none;

}
.scroll-ul {
    max-height: 200px;
    overflow-y: scroll;

}
.react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    font-size: 20px !important;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
}
.winner-table td {
    padding: 10px;
    font-weight: 500;
    border: none;
    width: 15%;
    vertical-align: middle;
  }
.players ul.icons li {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 10px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: -30px;
    font-size: 12px;
}
    .players ul.icons li:first-child{
        background: #D7385E;
    }
    .players ul.icons li:nth-child(2){
        background: #FF9200;
    }
    .players ul.icons li:nth-child(3){
        background: #DD2C00;
    }
    .players ul.icons li:nth-child(4){
        background: #FF5722;
    }
    .players ul.icons li:nth-child(5){
        background: #4CD3C2;
    }
    .players ul.icons li:nth-child(6){
        background: #363636;
    }
    .players ul.icons li:nth-child(7){
        background: #FFBCBC;
    }    
    .players ul.icons li:nth-child(8){
        background: #FF9200;
    }
    .players ul.icons li:nth-child(9){
        background: #DD2C00;
    }
    .players ul.icons li:nth-child(10){
        background: #FF5722;
    }
    .players ul.icons li:nth-child(11){
        background: #4CD3C2;
    }
    .players ul.icons li:nth-child(12){
        background: #FFBCBC;
    }

    .players ul.icons li:last-child{
        background: #E1E1E1;
        color: #000;
        /* border: 1px solid #000; */
    }
    .players-list{
        box-shadow: 0px 15px 24px #6666E24F;
        position: absolute;
        /* z-index: 999999; */
        width: 85%;
        background: #fff;
        border-radius: 10px;
        padding: 20px;
        top: 41px;
        padding: 14px !important;
        /* display: none; */
        width: 320px;
        right: 17px;
    }
    .players-list li{ 
        padding-top: 13px;
        padding-bottom: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .players-list li img{
        width: 30px !important;
        /* height: 30px !important; */
        border-radius: 50%;
    }
    .disabledTicket {
        pointer-events: none;
        opacity: 0.4;
    }
    .disableRule {
        border: 2px solid #9fce9f !important;
        background-color: #9fce9f !important;
        color: white;
        cursor: not-allowed !important;
    }
    .list-arrow{
        width: 24px;
        right: 32px;
        position: absolute;
        top: 4px;
        cursor: pointer;
    }
/* // login */

.md-input {
    position: relative;
    margin-bottom: 20px;
    margin-top: 10px
    
}

.md-input-schedule {
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px
    
}
    .md-input .md-form-control {
        font-size: 16px;
        padding: 10px 10px 10px 5px;
        display: block;
        border: none;
        border: 1px solid #00000052;
        border-radius: 4px;
        box-shadow: none;
        width: 100%;
        color: #0D1424;
        font-size: 19px;
        padding-left: 20px;
        font-weight: 500;
    }
     
    .md-input label {
        color: #00000099;
        font-size: 19px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 15px;
        top: 11px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .md-input .bar:before {
        left: 50%;
    }

    .md-input .bar:after {
        right: 50%;
    }

    .md-input .highlight {
        position: absolute;
        height: 60%;
        width: 100px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
    }
    .md-input .md-form-control:focus , .md-input .md-form-control:valid{
        border: 2px solid #522D5B;
    }
    .md-input .md-form-control:focus ~ label, .md-input .md-form-control:valid ~ label {
    top: -8px;
    font-size: 12px;
    color: #000;
    background: #fff;
}
.md-input .bar:before, .md-input .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #03A9F4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.md-input .md-form-control:focus ~ .bar:before, .md-input .md-form-control:focus ~ .bar:after {
    width: 50%;
    
}
.custom-card{
    border-radius: 22px;
    box-shadow: 0px 22px 42px #97979726;
border: 1 solid #cccc;
}
.custom-card .card-body{
    padding: 2rem;
    padding-bottom: 0;
}
.custom-btn{
    background: #522D5B 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000033;
    border-radius: 4px;
    color: #fff;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    font-size: 22px;
    /* margin-bottom: 2rem; */
}
.custom-btn a{
    color: #fff;
}
.card-heding{
    color: #522D5B;
    font-size: 24px;
    border: 2px solid #FB7B6B;
    border-bottom: none;
    border-radius: 6px;
    padding: 6px;
    width: 210px;
    text-transform: uppercase;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: auto;
}
.inner-page{
    margin-top: 40px;
}
.resend{
    text-align: right;
    color: #522D5B;
    font-size: 20px;
}
.resend-signup{
    margin-top: -20px;
}
.do-have{
    color: #0D1424;
    font-size: 16px;
    
}
.do-have a{
    color: #0D1424;
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
    text-decoration: underline;
}
.user-profile-icon img{
    width: 110px;
    margin-bottom: 25px;
    margin-top: -20px;
}

.wallet-tbl th, td{
    border-top: none !important;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: left;
    color: #3F3F3F;
}
.wallet-tbl th{
    font-weight: 400;
    font-size: 20px;
}
/* .wallet-tbl tr  th:last-child{
    text-align: right;
    font-weight: bold;
} */
.wallet-tbl tr  td:last-child{
     font-weight: bold;
     text-align: right;
}
.wallet-tbl tr  td:last-child small{
    color: #FB7B6B;
    font-weight: 400;
    margin-left: 10px;
}
.username{
    background: #522D5B 0% 0% no-repeat padding-box;
    padding: 6px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-left: -36px;
    margin-right: -36px;
    margin-bottom: 14px;
}
/* login end */

/* file upload */
 .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
 
.inputfile + label {
    max-width: 100%;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
} 

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor ;
    margin-top: -0.25em;
    margin-right: 0.25em;
}
.inputfile-1 + label {
    color: #FB7B6B;
    border: 2px solid #FB7B6B;
    font-weight: 500;
}

/* file upload end */

/* dashboard */
.game-list-card{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 12px #49494952;
    border-radius: 22px;
    text-align: center;
    margin-bottom: 10px !important;
    width: 112%;
}
.game-list-card .heading{
    background: #FB7B6B 0% 0% no-repeat padding-box;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    padding: 10px;
    font-size: 24px;
    color: #fff;
    pointer-events: none;
}
.game-list-card ul {
    list-style: none;
    padding-left: 0;
    padding: 15px 25px;
    height: 300px;
    overflow: auto;
}
.game-list-card ul li{
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    font-size: 20px;
    color: #522D5B;
    /* opacity: 0.64; */
}
.game-list-card.next-game ul li{
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    font-size: 20px;
    color: #000;
    opacity: 1;
    cursor: pointer;
} 
.game-list-card.next-game ul li:hover{
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    font-size: 20px;
    color:#FB7B6B;
    opacity: 1;
} 
.game-list-card.next-game ul li.next-game-li{
    font-size: 22px;
}
.dashboard-page{
    text-align: center;
}
.dashboard-page  .middle-section h2{
    color: #522D5B;
    font-size: 36px;
    margin-top: 30px;
    margin-bottom: 25px;
}
.gauge{
    position: relative;
}
.gauge h3{
    position: absolute;
    top: 44%;
    left: 0;
    right: 0;
    margin: auto;
    color: #440556;
    font-size: 34px;
}
.join-gauge{
    width: 330px;
    height: 330px;
    background: transparent linear-gradient(146deg, #522D5B 0%, #774A82 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 9px 22px #8D5A9973;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: auto;
    margin-bottom: 70px;
}
.dashboard-page  .middle-section  .join{
    background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 26px;
    color: #fff;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.dashboard-page  .middle-section .middle{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #707070;
    padding: 40px;
    
}
.dashboard-page  .middle-section .middle .claim-btn{
    width: 100% ;    
}
.winner{
    background: #522D5B 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 26px;
    color: #fff;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
/* dashboard end */
/* dashboard-4 hsr */
.form-group.copy_link_group {
    max-width: 500px;
    margin: auto;
    position: relative;
    margin-top: 20px;
}

label[for="link_copy"] {
    position: absolute;
    right: 15px;
    top: 15px;
}
input#link_copy {
    height: 55px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 10px 50px 10px 15px;
}
.form-group.copy_link_group+ button {
    margin-top: 20px;
}
/* dashboard-4 hsr */

/* dashboard-5 hsr */
select#sel1 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 55px;
    text-align-last: center;
    -webkit-appearance: none;
}
select#selectGroup {
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 1px solid #707070;
    border-radius: 10px;
    height: 55px;
    text-align-last: center;
    -webkit-appearance: none;
}

button.date_time.my-2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 55px;
    width: 100%;
}


select#sel1 option {
    text-align: center;
}
select#selectGroup option {
    text-align: center;
}

button.date_time.my-2+button.claim-btn.my-2 {
    border-radius: 10px;
    height: 55px;
    width: 100%;
}

button.date_time.my-2+button.claim-btn.my-2 :disabled {
    border-radius: 10px;
    height: 55px;
    width: 100%;
    background: dimgrey !important;
}
.middle-section.new_game .middle{border: 0; box-shadow: none;}
/* dashboard-5 hsr */

/* winner list */
.winner-heading{
    color: #522D5B;
    font-size: 24px;
    /* border: 2px solid #FB7B6B; */
    border-radius: 6px;
    padding: 6px;
    width: 210px;
    text-transform: uppercase;
    border-radius: 6px; 
    margin: auto;
    font-weight: 600;
}
.join-next{
    background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 24px;
    border: navajowhite;
    padding: 10px 37px;
    position: absolute;
    right: 17px;
    top: 7px;
    cursor: pointer;
}
.winner-table th{
    text-align: left !important;
    border: none;
    font-weight: 400;
    width: 30%;
}
.winner-table tr th:last-child{
    width: 10%;
}

.winner-table td{
    padding: 10px;
    font-weight: 500;
    border: none;
    width: 30%;
    vertical-align: middle;
}
 
.winner-table tr td:last-child{
    text-align: right;
    width: 10%;
}
.winner-table tr th:last-child{
    text-align: right !important;
}
.winner-table td img{
    width: 45px;
    height: 45px;
    margin-right: 10px;
}
.winner-table td {
    padding:15px 15px !important;
}
.winner-table:first-child {
    box-shadow: none;
}
.winner-table  {
    box-shadow: 0px 2px 6px #522D5B5C;
}

/* hsr css */
input#show_desk_table,label[for="show_desk_table"] {
    display: none;
}

/* hsr css */
/* winner list end */

@media(max-width: 1600px){
    .modalText{
        margin-left : 110px
        }
        .claimIntiated {
            background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat;
        border: 10px;
        border-radius: 5px;
        z-index: 10;
        color: white;
        margin-left: 90px;}
    .user-profile-icon img {
        width: 100px;
        margin-bottom: 10px;
        margin-top: -20px;
    }
    .right-icon img{
        width: 40px;
    }
    .table-1 tr td {
        width: 5%;
        color: black;
        font-size: 22px;
        padding: 0;
        font-weight: 500;
    }
    .table-1 tr td div {
        text-align: center;
        height: auto;
        padding-top: 9px;
        padding-bottom: 9px;
        padding-left: 3px;
        padding-right: 3px;
    }
    .table-responsive.table-1.desktop-view tr td div{
        padding:3px;
        font-size: 12px;
        
    }
    .next-card {
        padding: 20px;
    }
   
    .next-card .bottom-content p {
        font-size:45px;
    }
    .userpanel .item img {
        width: 55px;
        height: 55px;
    }
    .userpanel .item p {
        font-size: 14px;
    }
    
    .button-group .btn{
        font-size: 11px;
    }
    .right-card {
        padding-left: 74px;
        padding-right: 74px;
    }
    .user-profile-page{
        margin-top: 20px;
    }
     
    .user-profile-page .custom-card .card-body {
        padding: 2.25rem;
        padding-bottom: 0;
    }
    .user-profile-page .custom-btn{
        margin-bottom: 1.25rem;
    }
}
.desktop-el-display{
    display: block;
}
.mobile-el-display{
    display: none;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
@media(max-width: 767px){
    .game-rule-ul{
        padding-bottom: 0 !important;
    }
    .icon-font{
        position: relative;
        width: 10px;
    }
    .icon-desc {
        position: absolute;
        font-size: 9px;
        top: 12px;
        display: flex;
        flex-wrap: nowrap;
    }
    .note-label{
        position: sticky;
        bottom: 0;
        background: #fff;
        margin: 0 !important;
        padding: 8px 5px;
        border-top: 1px solid #c4c4c4cc;
        display: flex;
        font-size: 14px;
    }
    .game-rule-a{
        display: flex !important;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 0.25rem 1rem !important;
    }
    .game-rule-a .maxTicketInput{
        width: 80px;
        height: 36px;
        float: right;
    }
    .tickImg{
        top: 4px !important;
        right: 25px !important;
    }
    .select-group-link{
        width: 100%;
    }
    .select-group-link .newMdInput{
        width: 100%;
    }
    .select-group-link .newMdInput input{
        text-align: left;
        padding: 2px 30px 8px 15px !important;
        margin: 0;
        width: 103%;
    }
    .date_Picker{
        height: 39px !important;
    }
    .calenderImg{
        height: 18px !important;
    }
    .calenderImg {
        height: 19px;
        margin-left: 6px;
        float: right;
        margin-top: 4px;
    }
    .new-game-ticket-sec  {
        padding-left: 0;
    }
    .new-game-ticket-sec li{
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        margin-top: 14px;
    }
    .new-game-ticket-sec li h3{ 
        margin: 0 !important;
    }
    .new-game-ticket-sec li input{
        width: 100px;
        margin-left: 10px;
        height: 32px;
    }
    /* .new-game-ticket-sec li label{
        width: 70px;
        margin-left: 10px;
        height: 32px;
    } */
    .manualDiv{
        display: flex !important;
        /* flex-wrap: nowrap !important; */
        justify-content: space-between;
        align-items: baseline;
    }
    /* .manualDiv div b{
        display: none;
    } */
    .manualDiv .switch {
        position: relative;
        display: inline-block;
        margin: 10px;
        width: 55px;
        height: 25px;
    }
    .manualDiv .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 6px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
    }
    .entry-header{
        margin-top: 20px;
    }
    .entry-header .entry-title {
        font-size: 26px;
        margin-bottom: 0;
        font-weight: 700;
    }
    .winner-heading{
        font-size: 20px;
    }
    .last-played-tbl .table td, .table th {
        padding: 4px !important;
        vertical-align: top;
        border-top: 1px solid #dee2e6;
        line-height: 1.2;
        text-transform: capitalize;
        font-size: 13px;
        font-weight: 400;
    }
    .last-played-tbl  .table th {
        font-weight: 600;
    }
    h3.tckt_head {
        
        font-size: 14px !important;
        width: 56%;
       
    }
    .modalText{
        margin-left : 75px
        }
        .claimIntiated {
        background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat;
        border: 10px;
        border-radius: 5px;
        z-index: 10;
        color: white;
        margin-left: 53px;
    }
    .wallet-tbl th, td{
        font-size: 16px;
    }
    .ticket_price label.form-check-label{
        padding: 10px 13px !important;
    } 
    .ticket_price {
        margin-bottom: 8px !important;
    }
    .ticketNo b{
        display: none;
    }
    .ticket-section .ticket1{
        padding: 0;
        padding-top: 7px;
        margin-top: 38px;
    }
    .mobile-el-display{
        display: block;
    }
    .desktop-el-display{
        display: none;
    }
    .players {
        margin-top: 0;
        margin-bottom: 22px;
        margin-left: 15px;
    }
     
    .players.mobile-el-display .players-list{
        top: 0;
        position: relative;
        box-shadow: none;
        width: 100%;
        z-index: 0;
        padding-bottom: 0 !important;
        max-height: 200px;
        overflow-y: scroll;
    }
    .players.mobile-el-display h2{
        font-size: 17px;
        text-align: center;
        margin-bottom: 0;
    }
    .players.mobile-el-display .list-arrow {
        top: 0;
        right: 0;
        position: relative;
        width: 16px;
    }
    .players.mobile-el-display .players-list li img{
        margin-right: 10px;
    }
    .join-next {
        padding: 7px 28px;
        position: relative;
        right: 0;
        top: 7px;
        font-size: 20px;
    }
   .winner-back{
        /* position: absolute;
        top: 20px;
        left: 4px; */
        padding: 0 11px 2px !important;
    }
    .pagination-nav .pagination{
        float: none !important;
        /* justify-content: center; */
    }
    .pagination-nav{
        width: 100%;
        margin-top: 10px;
    }
    .winner{
        margin-top: 10px;
    }
    .dashboard-page .middle-section h2{
        margin-top: 16px;
        font-size: 24px;
        margin-bottom: 6px;
    }
    .dashboard-page .middle-section .join{
        width: 100%;
    }
    .game-list-card .heading {
        border-radius: 22px;
        cursor: pointer;
        pointer-events: auto;
        padding: 5px 8px 7px;
        font-size: 20px;       
    }
    /* .game-list-card ul{
        display: none;
    } */
    .game-list-card{
        margin-bottom: 9px;
        width: 100%;
    }
    .dashboard-page  .middle-section{
        order: 2;
    }
    .dashboard-page .first-section {
        order: 3;
    }
    .displayBlock{
        display: none !important;
        transition-duration: .4s;
        -webkit-transition: .4s;
  transition: .4s;
    }
    .mobile-view{
        display: block;
        margin-top: 40px;
        margin-bottom: 15px;
    }
    .owl-mobile .owl-nav .owl-prev{
        position: absolute;
        top: 1px;
        left: -12px;
        font-size: 29px !important;
    }
    .owl-mobile .owl-nav .owl-next{
        position: absolute;
        top: 1px;
        right: 0;
        font-size: 29px !important;
    }
    .owl-mobile .owl-item{
        margin-right: 0 !important;
        height: 43px;
        border: 1px solid #ccc;
    }
    .owl-mobile .item{
        
        padding: 10px;
        
    }
    .owl-mobile .item.active{
        background: #FB7B6B;
    border-radius: 22px;
    text-align: center;
    color: #fff;
    }
    .rules{
        margin-top: 20px;
    }
    .rules h2 {
        font-size: 18px;
    }
    .rules ul li{
        padding-bottom: 10px;
    }
    .rules ul {
        font-size: 15px;
        padding-left: 15px;
    }
    .winning h2 {
        font-size: 18px;
    }
    .winning ul {
        font-size: 15px;
        padding-left: 15px;
    }
/* hsr css */
.mobile-view{
    display: none;
}
.table-responsive.table-1.desktop-view {
    position: absolute;
    left: -110%;
    transition: all 1s;
    width: 200px;
    height: 300px;
    background: #fbfbfb;
    z-index: 99;
    border-radius: 5px;
}

button:focus + .desktop-view {
    left: 0;
}

input[type="checkbox"]:checked ~ .desktop-view {
    left: 15px;
}

input#show_desk_table {
    display: none;
}
label[for="show_desk_table"] {
    display: inline-block;
    position: relative;
    padding: 5px 7px;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    margin-top: 25px;
    background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat;
    overflow: hidden;
    top: 20px;
    cursor: pointer;
}
label[for="show_desk_table"]:after{display: none;}
input[type="checkbox"]:checked+label[for="show_desk_table"]:after {
    content: 'Hide Numbers';
    position: absolute;
    left: 0;
    width: 100%;
    background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat;
    text-align: center;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* hsr css */
.Myfooter{
    background-color: darkslategray;
}

    header{
        padding-bottom: 10;
    }
    .top-row{
        padding-left: 5px;
        padding-right: 5px;
        justify-content: center;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .top-row.right {
        justify-content: flex-end;
    }
    .top-info .col{
        padding: 0;
    }
    .top-row li {
        font-size: 10px;
        align-items: center;
        display: flex;
        padding-left: 2px !important;
        padding-right: 2px !important;
        color: #4A4A4A;
        margin: 0;
        padding: 0;
    }
    .top-row li img {
        margin-right: 3px;
        height: 12px;
    }
    .top-section hr {
        width: 55%;
    }
    .table-1 tr td div {
        width: auto;
        padding: 5px;
        height: auto;
        font-size: 12px;
        color: #000;
    }
    .table-1 tr td {
        width: 5%;
        font-size: 18px;
    }   
   
    .right-card-sec{
        /* position: absolute !important;
        top: -45px;
        right: 0;
        z-index: 0;
        width: 180px !important; */
    }
    .right-card {
        padding-left: 43px;
        padding-right: 45px;
        margin-top: 0;
        padding: 0;
        width: 165px;
        z-index: 999;
        background: #fff;
    }
    .last_five_nums{
        margin-left: 0;
    }
    .next-card .bottom-content {
        margin-top: 0px;
        margin-left: 0px;
    }
    .next-card{
        width: auto;
        margin: auto;
        margin-bottom: 0;
        padding: 8px;
    }
    .count_watch{
        margin-top: 0;
        margin-bottom: 0;
    }
    .next-card .number{
        min-height: auto;
        font-size: 4.5vw;
        width: 122px !important;
        margin-right: 15px;
    }
    .next-card .bottom-content p {
        font-size: 30px;
    }
    .next-card .bottom-content small {
        font-size: 11px;
    }
    .container-fluid {
        width: 100% !important;
        padding: 0 !important;
    }
    .md-input .md-form-control{
        font-size: 15px;
        padding: 5px 10px 5px 5px;
    }
    .inputfile + label{
        padding: 0.325rem 1.25rem;
        font-size: 19px;
    }
    .user-profile-page .custom-card .card-body{
        padding: 2.25rem 12px 0;
    }
    .username{
        margin-left: -12px;
        margin-right: -12px;
    }
    .card-heding{
        padding: 2px;
        width: 180px;
        font-size: 19px;
        margin-top: 15px;
    }
    .ticketNo {
        color: #FFFFFF;
        background: #676767;
        border-radius: 5px;
        font-size: 12px;
        border: none;
        width: auto;
        font-weight: 300;
        cursor: pointer;
        padding: 6px;
        margin-bottom: 0 !important;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
        border: 1px solid #000000;
        margin: 0px auto 10px;
        /* width: 170px !important; */
    }
    .claim-btn {
        margin-bottom: 0 !important;
        /* font-size: 21px; */
        /* font-size: 12px; */
        padding: 6px 10px;
        width: auto;
        width: 100%;
    }
    .ticket1{
        margin-top: -25px;
        padding-left: 0;
        padding-right: 0;
    }
    
    .ticket1 .col-md-6{
        text-align: center !important;
        padding-right: 0;
        margin-right: -14px;
    }
    .ticket1 .col-md-3{
        padding-right: 0;
        margin-right: -15px;
    }
    .ticket2{ 
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
    .ticket2 .row{
        margin-right: 0;
    }
    .ticket2 .col-md-6{
        text-align: center !important;
        padding-right: 0;
        margin-right: -14px;
    }
    .ticket2 .col-md-3{
        padding-right: 0;
        margin-right: -15px;
    }

    /* ticket3 */
    .ticket3{ 
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
    .ticket3 .row{
        margin-right: 0;
    }
    .ticket3 .col-md-6{
        text-align: center !important;
        padding-right: 0;
        margin-right: -14px;
    }
    .ticket3 .col-md-3{
        padding-right: 0;
        margin-right: -15px;
    }

    /* ticket4 */
    .ticket4{ 
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
    .ticket4 .row{
        margin-right: 0;
    }
    .ticket4 .col-md-6{
        text-align: center !important;
        padding-right: 0;
        margin-right: -14px;
    }
    .ticket4 .col-md-3{
        padding-right: 0;
        margin-right: -15px;
    }
    /* ticket5 */
    .ticket5{ 
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
    .ticket5 .row{
        margin-right: 0;
    }
    .ticket5 .col-md-6{
        text-align: center !important;
        padding-right: 0;
        margin-right: -14px;
    }
    .ticket5 .col-md-3{
        padding-right: 0;
        margin-right: -15px;
    }
    /* ticket6 */
    .ticket6{ 
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
    .ticket6 .row{
        margin-right: 0;
    }
    .ticket6 .col-md-6{
        text-align: center !important;
        padding-right: 0;
        margin-right: -14px;
    }
    .ticket6 .col-md-3{
        padding-right: 0;
        margin-right: -15px;
    }
    /* ticket7 */
    .ticket7{ 
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }
    .ticket7 .row{
        margin-right: 0;
    }
    .ticket7 .col-md-6{
        text-align: center !important;
        padding-right: 0;
        margin-right: -14px;
    }
    .ticket7 .col-md-3{
        padding-right: 0;
        margin-right: -15px;
    }

    .right-icon {
        position: static;
        margin-right: 0;
        margin-top: 10px;
        /* z-index: 99999; */
    }
    .right-icon img:first-child {
        margin-right: 5px;
    }
    .right-icon img {
        width: 18px !important;
    } 
    .right-icon .dropdown-toggle{
        display: flex;
        align-items: center;
    }
    .right-icon .dropdown-toggle::after{
        margin-left: 0;
    }
    .userpanel {
        margin-top: 0;
    }
    .userpanel .owl-dots {
        display: none;
    }
    header .container-fluid {
        /* display: flex;
        justify-content: space-between;
        align-items: center;
        padding:0; */
        text-align: center;
    }
    .right-icon>a{
        margin-right: 9px !important;
        margin-left: 9px;
    }
    .logo{
        margin-top: 0;
        width: 100%;
        font-size: 26px;
        margin-left: 0;
        text-align: center;
        /* z-index: 99999; */
    }
    .logo img{
        margin-top: 0;
        width: 120px;
        /* z-index: 99999; */
    }
    .head_nav .winner-heading{
        padding: 5px 12px;
        font-size: 12px;
    }
    .head_nav .join-next{
        padding: 5px 12px;
        font-size: 14px;
    }
    .head_nav .join-next a{ 
        color: #fff;
    }
    .winner{width: 100%;}
    .button-group{
        display: flex;
        flex-wrap: nowrap;
        margin-top: 5px !important;
        margin: 0;
        margin-left: 12px;
    }
    .button-group .col-sm-4   {
        width: 33% !important;
        padding-right: 5px;
        padding-left: 5px;
   }
    .button-group:last-child .col-sm-3{
        width: 50%;
    }
    .button-group .col-sm-3, .col-sm-9{
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
    .button-group .btn {
        margin-bottom: 5px;
        font-size: 11px;
    }
    .mobile-order{
        display: flex;
        flex-wrap: wrap;
    }
    .mobile-order .ticket-section{
        order: -1;
        margin-bottom: 30px !important;
        width: 100%;
        margin-top: 39px !important;
    }
    .md-input label{
        top: 6px;
        font-size: 15px;
    }
    .next-card.counter_down{
        padding: 0 0px 8px;
    }
    .next-card.counter_down h2{
        font-size: 12px;
        padding: 5px 6px;
        margin-bottom: 0;
    }
    .count_watch span{
        padding: 3px;
        font-size: 11px;
    }
    .mobile-order .row{
        width: 100%;
    }
    
}
.closeImg{
    cursor: pointer;
}
@media(max-width:359px){
    .next-card .number{
        font-size: 18px;
    }
}
.Myfooter{
    background-color: darkslategray;
}
@media(min-width: 767px) and (max-width: 991px){
    .winner-table {
        box-shadow: none;
        table-layout: fixed;
      }
    .right-card{
        margin-bottom: 30px;
    }
    .players {
        margin-bottom: -15px;
        margin-top: 41px;
    }
    .top-row li {
        font-size: 19px;
        padding-left: 9px;
        padding-right: 14px;
    }
    .button-group .btn {
        font-size: 13px;
    }
    .list-arrow {
        top: 34px;
    }
    .ticket2{
        margin-top: 25px;
    }
    .players-list{
        top: 66px;
    }
}

@media(min-width: 768px) and (max-width: 1100px){

    
    .top-row li {
        font-size: 19px;
        padding-left: 35px;
        padding-right: 35px;
    }
    .right-card {
        padding-left: 24px;
    }
    .table-1 tr td div {
        text-align: center;
        height: 55px;
        padding-top: 8px;
        padding-left: 3px;
        padding-right: 3px;
        width: 40px;
    }
    .table-1 tr td {
        font-size: 27px;
    }
   
    .button-group .btn {
        font-size: 11px;
    }
}

@media(min-width: 992px) and (max-width: 1200px){
    .right-card {
        padding-left: 23px;
    }
    .last_five_nums{
        margin-left: 23px;
    }
    .button-group .col-sm-4, .col-sm-8{
        padding: 0 5px;
    }
    .button-group .btn {
        font-size: 13px;
    }
    .table-1 tr td{
        font-size: 18px;
    }
    .players ul.icons li{
        padding: 7px;
    }
}

.rdt {
    position: relative;
  }
  .rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
  }
  .rdtOpen .rdtPicker {
    display: block;
  }
  .rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
  }
  
  .rdtPicker .rdtTimeToggle {
    text-align: center;
  }
  
  .rdtPicker table {
    width: 100%;
    margin: 0;
  }
  .rdtPicker td,
  .rdtPicker th {
    text-align: center;
    height: 28px;
  }
  .rdtPicker td {
    cursor: pointer;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
  }
  .rdtPicker td.rdtOld,
  .rdtPicker td.rdtNew {
    color: #999999;
  }
  .rdtPicker td.rdtToday {
    position: relative;
  }
  .rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  }
  .rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
  }
  .rdtPicker td.rdtDisabled,
  .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  
  .rdtPicker td span.rdtOld {
    color: #999999;
  }
  .rdtPicker td span.rdtDisabled,
  .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
  }
  .rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
  }
  .rdtPicker th.rdtSwitch {
    width: 100px;
  }
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
  }
  
  .rdtPrev span,
  .rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  
  .rdtPicker th.rdtDisabled,
  .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
  }
  .rdtPicker thead tr:first-child th {
    cursor: pointer;
  }
  .rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
  }
  
  .rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
  }
  
  .rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
  }
  .rdtPicker button:hover {
    background-color: #eee;
  }
  
  .rdtPicker thead button {
    width: 100%;
    height: 100%;
  }
  
  td.rdtMonth,
  td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
  }
  td.rdtMonth:hover,
  td.rdtYear:hover {
    background: #eee;
  }
  
  .rdtCounters {
    display: inline-block;
  }
  
  .rdtCounters > div {
    float: left;
  }
  
  .rdtCounter {
    height: 100px;
  }
  
  .rdtCounter {
    width: 40px;
  }
  
  .rdtCounterSeparator {
    line-height: 100px;
  }
  
  .rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
  
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
  }
  .rdtCounter .rdtBtn:hover {
    background: #eee;
  }
  .rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
  }
  
  .rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
  }
  
  .rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
  }
  
  .rdtTime td {
    cursor: default;
  }
  ul.group_head {
    padding: 15px 15px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, .125);
    margin: 0;
    border-bottom: 0;
    border-radius: 5px 5px 0px 0px;
    margin-top: 2em;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 6px #522D5B5C;
    border-radius: 2px;
    border: 0;
}
ul.group_head li {
    min-width: 0;
    flex: 1 1 0;
    font-size: 18px;
    font-weight: 400;
    color: #522D5B;
}
.head_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.head_nav .winner-heading {
    display: inline-block;
    width: auto;
    padding: 5px 12px;
}
.head_nav .join-next {
    position: static;
}
.head_nav .join-next a{ 
    color: #fff;
}
ul.group_head.group_data li {
    overflow: hidden;
    text-overflow: ellipsis;
}
ul.group_head li{
    font-size: 14px;
}
.head_nav .back {
    background: #522D5B 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #fff;
    opacity: 99999;
    padding: 0px 9px 1px !important;
}
.dashboard-page.manage_group .card-header {
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.dashboard-page.manage_group .card-body h3 {
    font-size: 22px;
    text-align: left;
    font-weight: 500;
}
.dashboard-page.manage_group .card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 6px #522D5B5C;
    border-radius: 2px;
    margin-bottom: 20px;
    border: 0;
}
.dashboard-page.manage_group .card-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    text-decoration: none;
}
.dashboard-page.manage_group .card-link[aria-expanded="true"] img {
    transform: rotate(180deg);
}
ul.group_head.group_data {
    margin: 0;
    padding: 10px 0;
    border: 0;
    overflow: hidden;
    font-size: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    border: 0;
}
ul.group_head.group_data li {
    font-size: 14px;
    font-weight: 400;
    color: #1C1C1C;
}
.action {
    max-width: 187px;
    position: relative;
    z-index: 999999;
    margin: auto;
}
.action a {
    margin: 0 10px;
    display: inline-block;
}
ul.parti_ul {
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin: 0;
    flex-wrap: wrap;
}
ul.parti_ul li {
    flex: 0 0 19%;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 50px;
    padding: 5px 10px;
    flex-wrap: wrap;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
ul.parti_ul li>img {
    width: 35px;
    height: 35px;
}
ul.parti_ul li span {
    font-size: 14px;
}
/* manage group */
/* create group */
.dashboard-page.create_group form {
    max-width: 700px;
    margin: 2em auto 0;
}
.dashboard-page.create_group .add_phone {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 55px;
    display: flex;
    align-items: center;
    padding: 15px;
}
.dashboard-page.create_group .add_phone ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.dashboard-page.create_group .add_phone ul li span {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 22px;
    padding: 5px 15px;
}
.dashboard-page.create_group .add_phone ul li a {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 50%;
    padding: 4px 10px;
}
.dashboard-page.create_group .form-group label {
    text-align: left;
    width: 100%;
    color: #522D5B;
}
.dashboard-page.create_group input#group_name {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 55px;
    display: flex;
}
.dashboard-page.create_group .form-group label a {
    float: right;
}
.dashboard-page.create_group .form-group {
    margin-bottom: 1.5rem;
}
.dashboard-page.create_group .create-btn {
    background: transparent linear-gradient(180deg, #FB7B6B 0%, #FF3B23 100%) 0% 0% no-repeat padding-box;
    border-radius: 10px;
    border: 0;
    padding: 10px 20px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 22px;
}
.btn-group {
    flex-direction: column;
}
.input-group.number-spinner input {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #494949;
    font-size: 22px;
}
span.input-group-btn button {
    padding: 1px 10px;
    border-radius: 0;
    border: 0;
    background: transparent;
}
.input-group.number-spinner {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 42px;
    overflow: hidden;
    max-width: 100px;
}
/* create group */
/* create new game */
.dropdown.select_rules {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    height: 55px;
    width: 100%;
    line-height: 55px;
    color: #000;
    margin: 7px 0px 0;
}
.dropdown.select_rules a{
    color: #7B7B7B; text-decoration: none; display: flex; align-items: center;
}
.dropdown.select_rules .dropdown-item.active,.dropdown.select_rules .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #522d5b;
}
ul.dropdown-menu.rules_li {
    position: static!important;
    width: 100%;
    transform: unset !important;
    will-change: unset !important;
    line-height: normal;
    border: 1px solid #707070;
    margin: 0;
}
ul.dropdown-menu.rules_li a label{
    margin: 0; margin-left: 10px;
}
.dropdown.select_rules> a {
    color: #000;
    text-decoration: none;
    left: 0;
    width: 100%;
    display: block;
}
.ticket_price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.ticket_price input[type=radio] {
    display: none;
}
.ticket_price input:checked + label.form-check-label{
    background-color: #522D5B; color: #fff;
}
.ticket_price label.form-check-label {
    padding: 15px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    font-size: 20px;
    cursor: pointer;
}
.ticket_price .form-check:first-child {
    padding: 0;
}
h3.tckt_head {
    text-align: left;
    font-size: 16px;
    margin-top: 15px;
    
}
label.terms_con {
    display: block;
    text-align: left;
}
ul.dropdown-menu.rules_li li {
    clear: both;
    /* margin-bottom: 10px; */
}
ul.dropdown-menu.rules_li button {
    color: #ffffff;
    background: #522d5b;
    margin-right: 10px;
    box-shadow: none;
    border: 0;
    /* float: right; */
    cursor: pointer;
    margin-bottom: 10px;
    /* position: absolute;
    right: 10px; */
}
.doneButton  {
    color: #522D5B;
    background: transparent;
    box-shadow: none;
    border: 0;
    float: right;
    cursor: pointer;
    margin-bottom: 10px;
    /* position: absolute;
    right: 10px; */
}
.done_check .rules_sel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.done_check .rules_sel input {
    max-width: 25px;
    height: 40px;
    margin-left: auto;
    margin-right: 5px;
}
.rules_sel h4 {
    margin-left: 20px;
    margin-right: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #AAAAAA;
    padding: 10px 15px;
    font-size: 15px;
    min-width: 172px;
    /* margin: 0; */
    width: 70%;
}
.done_check {
    padding: 15px;
}
.rules_sel label {
    margin: 0;
}
.owl-carousel .owl-item img {
    display: block;
    width: 100% !important; 
}
.myModal {
    top: 419px;
left: 619px;
width: 682px;
height: 253px;
background: #FFFFFF 0% 0% no-repeat padding-box;
opacity: 1;
}
.customInput{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: thin;
    border-bottom-color: black;
}
.customInput :focus ::selection{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-width: thin;
    border-bottom-color: black;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    z-index: 99 !important;
}

  .winner-table td {
    padding: 10px;
    font-weight: 500;
    border: none;
    white-space: nowrap;
    vertical-align: middle;
  }
  

  .winner-table {
    box-shadow: none;
  }
  .winner-table th {
    text-align: left !important;
    border: none;
    font-weight: 400;
    width: 15%;
  }

  .Myfooter{
    background-color: darkslategray;
}

.specialLink {
    color: #007bff !important;
        text-decoration: none !important;
        background-color: transparent !important;
        -webkit-text-decoration-skip: objects;
        cursor: pointer;
}
.specialLink:hover{
    color: #0056b3 !important;
        text-decoration: underline !important;
}
.smallBtn {
    padding: 2px !important;
}

.addNewGroupImg {
    position: relative;
      top: 4px;
      right: 20px;
  }
  
  .newMdInput {
    margin-bottom: 0px !important;
  }

  .container {
    display: block;
    position: relative;
    padding-left: 35px !important;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    border: 3px solid !important;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
    border: 3px solid;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
    height: 10px;
    margin: auto;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  .refesh-icon{
      cursor: pointer;
      margin-top: 4px;
  }